import React, { Component } from "react";

export class Services extends Component {
  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
            <p>
            We ensure that our service offerings bring value to our clients.
            </p>
          </div>
          
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/Brand-Management.png" className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h1>Brand Management</h1>
                {/* <h5 style={{textAlign:"left"}} >How you look + How you speak + How you act = Your Personal Brand</h5>
                <h5 style={{textAlign:"left"}}>What company does + How company does it + What customer experience = Professional Brand </h5> */}
                <p style={{textAlign:"left"}}>Just like our birthmark, intelligence, empathy and moral values which are inherited within us makes our personal brand… Similarly, businesses of today need that distinguished name, term, slogan, symbol, design, message and customer experience which makes them UNIQUE.</p>
                <p style={{textAlign:"left"}}>We at DreamStick help you develop or improve that unique selling factors for your business which helps you meet your business goals. Dreamstick’s brand management services for you:</p>
                <div style={{marginLeft:15}}>
                <ul style={{listStyleType: "disc",textAlign:"left",color:"#777",fontSize:15}}>
                  <li>Brand consultancy</li>
                  <li>Branded collateral development</li>
                  <li>Office branding</li>
                  <li>Brand guidelines and trainings</li>
                  <li>Brand building and positioning</li>   
                </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr/>

        <div className="container" >
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h1>Design & Creative Communication</h1>
                {/* <h5 style={{textAlign:"left"}} >How you look + How you speak + How you act = Your Personal Brand</h5>
                <h5 style={{textAlign:"left"}}>What company does + How company does it + What customer experience = Professional Brand </h5> */}
                <p style={{textAlign:"left"}}>Communication is common, but effective one is rare. We help make your communication better and connect you effectively with your stakeholders and creatively ofcourse. With industry best practices and Dreamstick’s expertise, we bring ideas amalgamated with creative design and content on the table to serve your business goals. Our set of services for design and creative communication includes:</p>
                <div style={{marginLeft:15}}>
                <ul style={{listStyleType: "disc",textAlign:"left",color:"#777",fontSize:15}}>
                  <li>Customized collateral design & development</li>
                  <li>Video development </li>
                  <li>Presentation design</li>
                  <li>Document formatting</li>
                  <li>Customized Packaging</li>
                  <li>Website design</li>
                  <li>Application (App) UI design</li>     
                </ul>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6"> <img src="img/Design.png" className="img-responsive" alt=""/> </div>
          </div>
        </div>

        <hr/>

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/Business-Writing.png" className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h1>Business Writing</h1>
                {/* <h5 style={{textAlign:"left"}} >How you look + How you speak + How you act = Your Personal Brand</h5>
                <h5 style={{textAlign:"left"}}>What company does + How company does it + What customer experience = Professional Brand </h5> */}
                <p style={{textAlign:"left"}}>Every brand has an information to tell or a product to sell or a service to offer, but knowing the audience and then speaking what the customers want to hear and telling them stories of what your brand is built of makes it compelling. So a content is the soil in which inbound success is grown. Don’t be the same be better. So a clear, compelling and concise way of communication with the customer is the key. As we know easy reading is something which is hard writing. So at Dreamstick our professional writers and experienced creative communication artists or so called writers and designers can help make this issue easier for you. Set of services being offered as part of it includes:</p>
                <div style={{marginLeft:15}}>
                <ul style={{listStyleType: "disc",textAlign:"left",color:"#777",fontSize:15}}>
                  <li>Professional content curation</li>
                  <li>Corporate presentations or pitch packs development</li>
                  <li>Proposals and bid preparation</li>
                  <li>Social Media content development</li>
                  <li>Product or service write-ups</li>   
                </ul>
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <hr/>

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h1>Promotions</h1>
                {/* <h5 style={{textAlign:"left"}} >How you look + How you speak + How you act = Your Personal Brand</h5>
                <h5 style={{textAlign:"left"}}>What company does + How company does it + What customer experience = Professional Brand </h5> */}
                <p style={{textAlign:"left"}}>Promotions in today’s digital word is influencing the customers and building the long term relationship. Dreamstick can help you develop or improve your existing marketing plan. Dreamstick also provides you with digital and social media related services to support the marketing plan, and thus provide you with the best of cushioning where you can relax and rely on Dreamstick to lead your promotions. Be it offline promotions or digital ones, we can support on a continued mode. Our set of services for promotions include:</p>
                <div style={{marginLeft:15}}>
                <ul style={{listStyleType: "disc",textAlign:"left",color:"#777",fontSize:15}}>
                  <li>Brand campaigns</li>
                  <li>Digital promotions</li>
                  <li>Social media management</li>
                  <li>Social media marketing</li>
                  <li>Offline advertising</li>   
                </ul>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6"> <img src="img/Promotions.png" className="img-responsive" alt=""/> </div>
          </div>
        </div>

        <hr/>

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/Event-Management.png" className="img-responsive" alt=""/> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h1>Event Management</h1>
                {/* <h5 style={{textAlign:"left"}} >How you look + How you speak + How you act = Your Personal Brand</h5>
                <h5 style={{textAlign:"left"}}>What company does + How company does it + What customer experience = Professional Brand </h5> */}
                <p style={{textAlign:"left"}}>Struggling with managing the strategy, logistics and planning for your event? Dreamstick is the dream destination for you to stop and see how it can help make your event come to life. Right from planning, to coordination, to execution or to analyse the impact of your spending and help you plan better on your marketing spends.</p>
                <p style={{textAlign:"left"}}>We will provide you the opportunities where you can focus on interactions with the stakeholders and we manage the rest, be it managing event logistics, communication or promotions. The set of services we offer:</p>
                <div style={{marginLeft:15}}>
                <ul style={{listStyleType: "disc",textAlign:"left",color:"#777",fontSize:15}}>
                  <li>Event creation & planning</li>
                  <li>Event marketing</li>
                  <li>Photoshoots</li>
                  <li>Event merchandise</li>
                </ul>
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <hr/>
      
        </div>
      </div>
    );
  }
}

export default Services;
