import React, { Component } from "react";

export class Header extends Component {
  render() {
    return (
      <div className="hero-container">
        <video src="/videos/video-17-2.mp4" autoPlay loop muted />
        {/* <header style={{color:"White"}}>
          <h1 class="headtext" id="text1">Integrity.Professionalism.Empathy.</h1>
          <p class="headtext" id="text1" style={{textAlign:"center"}}>…are the values which defines us</p>
          <h1 class="headtext" id="text2">Creative.Intellectual.Responsible.</h1>
          <p class="headtext" id="text2" style={{textAlign:"center"}}>…are the traits we embrace</p>
        </header> */}
        <div id="wrapper">
		      <p id="hi"></p>
	      </div>
        <a href="#about" className="btn btn-custom btn-lg page-scroll">
          Who We Are
        </a>{" "}
        {/* <p style={{ fontFamily:"Raleway", fontSize:"2.5vw"}}>Who We Are..</p> */}
      </div>

  
      
    );
  }
}

export default Header;
